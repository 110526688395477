import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
  CanDeactivate,
  CanLoad
} from '@angular/router';
import { Route } from '@angular/compiler/src/core';
import { RunService, CacheService, UtilsService, XMapService, EchoService } from './shared/services';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { PAGESUBTITLE } from './shared/config';

@Injectable({ providedIn: 'root' })
export class AppGuard implements CanLoad, CanActivate, CanActivateChild, CanDeactivate<any> {

  urls: string[] = [];

  constructor(
    private router: Router,
    private runService: RunService,
    private echoService: EchoService,
    private cacheService: CacheService,
    private utilService: UtilsService,
    private xmapService: XMapService,
    private http: HttpClient,
  ) { }

  canLoad(route: Route) {

    return true;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.xmapService.loadQqMapJs();
    const url = state.url;

    this.runService.analysisUrl(url);

    const uid = this.runService.uid;
    if (uid) {
      let title = route.data.title;
      if (!title) { title = '电子地图' + PAGESUBTITLE; }
      this.http.put('/frontend/useredu/footprint', {title, url: environment.vmap + url.split('?')[0]}).toPromise().then();
    }

    const rid = route.data.rid;
    if (rid) {
      this.xmapService.dwTrapMap(rid, uid);
    }

    if (uid && rid) {
      this.http.get('/frontend/box/pick?scene=map&event=*&label=' + route.data.title).toPromise().then((resp: any) => {
        if (resp.got > 0 && !this.urls.includes(state.url)) {
          this.urls.push(state.url);
          this.echoService.showExperience(resp.got);
        }
      });
    }

    return true;
  }

  canActivateChild() {

    return true;
  }

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot) {

    return true;
  }
}
