import { Component } from '@angular/core';
import { RouterOutlet, Router, NavigationEnd } from '@angular/router';
import { slideInAnimation } from './animations';
import { Title } from '@angular/platform-browser';

import {
  PerfectScrollbarModule, PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarComponent, PerfectScrollbarDirective, PerfectScrollbarConfig
} from 'ngx-perfect-scrollbar';

// import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
// import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
// import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
// const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
//   suppressScrollX: true
// };
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,// 是否在末尾传播滚轮事件（默认：false）。
  suppressScrollY: true, // 在所有情况下禁用Y轴（默认：false）。
  suppressScrollX: true  // 在所有情况下禁用X轴（默认：false）。
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [ slideInAnimation ]
})
export class AppComponent {

  constructor(titleService: Title, router: Router) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const title = this.getTitle(router.routerState, router.routerState.root).join('-');
        titleService.setTitle(title);
      }
    });
  }

  getTitle(state: any, parent: any) {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) { data.push(parent.snapshot.data.title); }

    if (state && parent) { data.push(... this.getTitle(state, state.firstChild(parent))); }

    return data;
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }
}
